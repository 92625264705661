<script setup lang="ts">
import type { PublicationSummary } from './card/index.vue'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { Card } from '#components'

const props = defineProps<{
	publications: PublicationSummary[]
}>()

const running = ref(true)
const cardRefs = ref<InstanceType<typeof Card>[]>([])
const restart = () => {
	cardRefs.value.forEach((card) => card.showCardFront())

	if (!running.value) {
		leaveFromPosition.value = goToPosition.value
		goToPosition.value =
			goToPosition.value + 1800 - Math.round(Math.random() * 18) * 20
		running.value = true
	}
}
const leaveFromPosition = ref(-1800)
const goToPosition = ref(0)

const AMOUNT = 18
const ANGLE = 360 / AMOUNT
const cards = computed(() => {
	const publications = props.publications.slice(0, AMOUNT)
	const amountMissing = AMOUNT - publications.length
	const cards: PublicationSummary[] = publications
	for (let i = 0; i < amountMissing; i++) {
		const extra = publications[i % publications.length]
		cards.push(extra)
	}

	return cards.sort(() => Math.random() - 0.5)
})

const closeButtonDisabled = ref(true)
onMounted(() => {
	setTimeout(() => (closeButtonDisabled.value = false), 1500)
})
</script>

<template>
	<section
		class="fixed pt-[25vh] top-0 left-0 overflow-hidden w-full h-screen bg-black/10 backdrop-blur-2xl z-[101] casino-section"
	>
		<UseFocusTrap :options="{ immediate: true, preventScroll: true }">
			<div class="absolute w-full z-20 top-16 text-center">
				<Button
					size="large"
					color="green"
					type="button"
					@click="restart"
					class="start-rad-opnieuw mx-auto"
					:class="{
						'opacity-20': running,
					}"
					>Verras me nog eens</Button
				>
			</div>

			<div class="v-motion-slide-bottom">
				<div
					class="w-full translate-x-[calc(50%_-_145px)] h-[100vw] mx-auto"
					:class="{
						'animate-casino-end': running,
					}"
					@animationend="running = false"
					:style="`--goToPosition: ${goToPosition}deg; --leaveFromPosition: ${leaveFromPosition}deg; --tw-rotate: ${goToPosition}deg; transform-origin: 145px 1400px; `"
				>
					<div
						v-for="(card, i) in cards"
						:style="{
							'transform-origin': 'center 1400px',
							transform: `rotate(${i * ANGLE}deg)`,
						}"
						class="absolute z-10"
					>
						<Card
							ref="cardRefs"
							:disable-focus="
								running ||
								(360 - i * ANGLE === 360 ? 0 : 360) -
									i * ANGLE !==
									goToPosition % 360
							"
							:publication="card"
						/>
					</div>
				</div>
			</div>

			<div
				class="absolute z-[100] bottom-8 w-full left-0 text-center"
				v-motion-slide-bottom
				:delay="1000"
			>
				<Button
					:aria-hidden="closeButtonDisabled"
					:disabled="closeButtonDisabled"
					size="small"
					color="black"
					type="button"
					@click="$emit('close')"
					class="sluit-rad mx-auto"
					:class="{
						invisible: closeButtonDisabled,
					}"
					>Sluiten</Button
				>
			</div>
		</UseFocusTrap>
	</section>
</template>
